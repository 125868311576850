$base-width: 8.33%;

.list {
	display: flex;
	padding-left: 0;
  margin-bottom: 16px;
	flex-direction: column;

	&__header {
		border-bottom: 1px solid rgba(0, 0, 0, 0.07);
		margin-bottom: 10px;
		padding-bottom: 5px;
		display: flex;
		flex-direction: row;
		margin-top: 30px;

		&--fix-padding {
			padding-bottom: 8px;
		}
	}

	&__title {
		display: flex;
		flex: 1 1 100%;
		font-size: 16px;
		font-weight: normal;
		margin: 0;
		line-height: 35px;

		> span {
			margin-right: 7px;
		}
	}

	&__button-group {
		display: flex;
	}

	&__list-header {
		display: flex;
		flex-direction: row;
		padding-left: 0;
		padding-bottom: 5px;
		color: $invono-grey;

		// List header grid
		&__col {
			flex: 1 1 100%;
			padding-left: 7px;
			padding-right: 7px;

			&--1 {
				flex: 0 0 $base-width;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--2 {
				flex: 0 0 $base-width * 2;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--3 {
				flex: 0 0 $base-width * 3;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--4 {
				flex: 0 0 $base-width * 4;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--5 {
				flex: 0 0 $base-width * 5;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--6 {
				flex: 0 0 $base-width * 6;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--7 {
				flex: 0 0 $base-width * 7;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--8 {
				flex: 0 0 $base-width * 8;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--9 {
				flex: 0 0 $base-width * 9;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--10 {
				flex: 0 0 $base-width * 10;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--11 {
				flex: 0 0 $base-width * 11;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--12 {
				flex: 0 0 100%;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--w50 {
				flex: 0 0 50px;
			}

			&--w180 {
				flex: 0 0 180px;
				padding-left: 7px;
				padding-right: 7px;
				width: 180px;
			}

			&--pad-hor-no {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__text-area {
			width: 90px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;

			&--sm {
				width: 10%;
			}

			&--x2 {
				width: 25%;
			}

			&--x3 {
				width: 40%;
			}

			&--x5 {
				width: 65%;
			}

			&--w90 {
				width: 90px;
			}

			&--w120 {
				width: 120px;
			}

			&--w150 {
				width: 150px;
			}

			&--pad-lft {
				padding-left: 8px;
			}

			&--pad-right {
				padding-right: 8px;
			}

			&--right {
				justify-content: flex-end;
			}
		}

		&__body {
			display: flex;
			flex: 1 1 100%;
			justify-content: flex-end;
			flex-direction: column;


			&--pad-lft {
				padding-left: 15px;
			}

			&--pad-right {
				padding-right: 15px;
			}
		}
	}

	&__item {
		display: flex;
		flex: 1;
		align-items: center;
		min-height: 50px;
		background-color: #fff;
		margin-bottom: 4px;
		color: #666;

		&__cert-left-item {
			display: flex;
			align-items: center;
			width: 200px;
		}

		&__pledge {
			display: flex;
			flex-direction: column;

			&__row {
				display: flex;
				flex-direction: row;
			}
			&__item{
				padding-right: 16px;
				padding-top: 4px;
				padding-bottom: 4px;
			}
		}
		&--pad-lft-8 {
			padding-left: 8px;
		}

		&--horizontal-flex {
			display: flex;
			flex-direction: row;
		}

		&--no-height {
			height: inherit;
		}

		&--auto-height {
			height: auto;
		}

		&--link {
			cursor: pointer;
		}

		// List item grid
		&__col {
			flex: 1 1 100%;
			padding-left: 7px;
			padding-right: 7px;

			&--1 {
				flex: 0 0 $base-width;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--2 {
				flex: 0 0 $base-width * 2;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--3 {
				flex: 0 0 $base-width * 3;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--4 {
				flex: 0 0 $base-width * 4;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--5 {
				flex: 0 0 $base-width * 5;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--6 {
				flex: 0 0 $base-width * 6;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--7 {
				flex: 0 0 $base-width * 7;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--8 {
				flex: 0 0 $base-width * 8;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--9 {
				flex: 0 0 $base-width * 9;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--10 {
				flex: 0 0 $base-width * 10;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--11 {
				flex: 0 0 $base-width * 11;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--12 {
				flex: 0 0 100%;
				padding-left: 7px;
				padding-right: 7px;
			}

			&--w50 {
				flex: 0 0 50px;
			}

			&--w180 {
				flex: 0 0 180px;
				padding-left: 7px;
				padding-right: 7px;
				width: 180px;
			}

			&--pad-hor-no {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&--pad-top {
			padding-top: 4px;
		}

		&--align-start {
			align-items: flex-start;
		}

		&--no-hover {
			&:hover, &:active {
				background-color: inherit !important;
			}
		}

		&--no-wrap {
			white-space: nowrap;
		}

		&__drag-area {
			width: 15px;
			height: 100%;

			&--neutral {
				background-color: $panel-bg-color;
			}

			&--red {
				background-color: $bg-color-red;
			}

			&--orange {
				background-color: $bg-color-orange;
			}

			&--green {
				background-color: $bg-color-green;
			}
		}

		&__total-shares {
			width: 100px;
			height: 100%;
			display: flex;
			align-items: center;
  		justify-content: flex-end;
			background-color: #339999;
			color: #fff;
			padding-left: 8px;
			padding-right: 8px;
			height: 50px;

			&--grey {
				background-color: $greyLight;
			}

		}

		&__text-area {
			width: 90px;
			height: 100%;
			display: flex;
			align-items: center;
  		justify-content: center;
			flex-shrink: 0;

			&--small {
				padding: 0 5px;
				width: 50px;
				line-height: 50px;
				display: block;
				text-align: center;
				vertical-align: middle;

			}

			&--sm {
				width: 10%;
			}

			&--x2 {
				width: 25%;
			}

			&--x3 {
				width: 40%;
			}

			&--x5 {
				width: 65%;
			}

			&--w90 {
				width: 90px;
			}

			&--w120 {
				width: 120px;
			}

			&--w150 {
				width: 150px;
			}

			&--pad-lft {
				padding-left: 8px;
			}

			&--pad-right {
				padding-right: 8px;
			}

			&--left {
				justify-content: flex-start;
			}

			&--right {
				justify-content: flex-end;
			}
		}

		&__dropdown {
			width: 45px;
			height: 100%;
			display: flex;
			align-items: center;
  		justify-content: center;
			flex-shrink: 0;
		}

		&__vertical-line {
			flex: 1;
			min-width: 1px;
			height: 35px;
			display: flex;
			background-color: $greyLight;
		}

		&__body {
			height: 100%;
			display: flex;
			flex: 1 1 100%;

			padding: 0 15px;
			justify-content: center;
			flex-direction: column;

			min-width: 0px;

			&--no-flex {
				flex: none;
			}
		}

		&__text {
  			text-overflow: ellipsis;
			white-space: nowrap;
  			overflow: hidden;

			&--strong {
				font-weight: bold;
			}
		}

		&__input {
			padding: 4px 0;
			width: 100%;
			display: block;

			&--pad-right {
				padding-right: 8px;
			}

			&--pad-lft {
				padding-left: 8px;
			}
			&--margin-left{
				margin-left: 8px;
			}
		}

		&__button {
			padding: 0 15px;
			width: 97px;
			text-align: center;
		}

		&__date {
			height: 100%;
			display: flex;
			align-items: center;
  			justify-content: center;
			background-color: $greyLight;
			padding: 16px;

			.icon-expire {
				width: 85px;
				text-align: center;
			}
		}

		&__arrow {
			height: 100%;
			display: flex;
			align-items: center;
  			justify-content: center;
			font-size: 24px;
			padding: 0 12px;
			color: $font-color-light;
		}

		&__expandable {
			padding-top: 15px;
			padding-bottom: 15px;
			background-color: $white;
		}

		&--active {
			background-color: #e2ebf1;
			border-color: transparent;
		}

		&--bg-green {
			@include bg-variant($font-color-white, $green);
		}

		&--bg-gray {
			@include bg-variant($gray-color, $bg-color-panel-darker);
		}

		&--bg-gray-dark {
			@include bg-variant($font-color-mid, $bg-color-gray-dark);
		}

		&--bg-orange {
			@include bg-variant($warning-color, $warning-bg);
		}

		&--color-white {
			color: $white;
		}


		// &:hover, &:focus{
    	// 	background-color: #dee6ec;
		// 	color: #666;
		// }
	}

	&--lg {
		width: 45%;
	}

	&--mar-btm-0 {
		margin-bottom: 0;
	}

	&--table {
		.list__item {
			margin-bottom: 0px;
			border-bottom: 1px solid $table-border-color;
			min-height: 46px;

			&:first-child {
				border-top: 1px solid $table-border-color;
			}
		}
	}

	&--striped {
		.list__item:nth-child(odd) {
			background-color: $table-color-dark;
		}
	}

	&--mar-btm-x2 {
		margin-bottom: 40px;
	}

	&__overflow {
		max-width: 1500px;
		flex: 1;
		overflow-x: auto;
	}
}
