.Select {
	&--up {
		.Select-menu-outer {
			top: auto;
			bottom: 100%;
		}
	}
}

/* Used by React-Select-v2 */

.react-select__menu {
	border: 1px solid #c3c3c3 !important;
	border-radius: 0px !important;
	box-shadow: none !important;
	margin-top: 0px !important;
	margin-top: -1px !important;
	z-index: 999 !important;
}

.react-select__option {
	/* Single line in options*/
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	cursor: pointer !important;

	&.react-select__option--is-disabled {
		&:active, &:focus, &:hover, :active, :focus, :hover {
			background-color: $white !important;
			cursor: not-allowed;
		}
	}

	:active, :focus, :hover {
		background-color: #ECF0F5;
		outline: none !important;
	}

	&--is-focused {
		background-color: #ECF0F5 !important;
		outline: none !important;
	}
}

.react-select__menu-list {
	padding: 0px !important;
}
