#app {
	display: flex;
	flex-direction: column;
	// bottom: 0;
	// left: 0;
	// overflow-x: auto;
	// overflow-y: hidden;
	position: fixed;
	// right: 0;
	// top: 0;
	z-index: 0;

	// Löser problem med horisontell scroll
	width: 100%;
	height: 100%;
	overflow: hidden;
}
