.processing-bar {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(51,51,51,0.5);
	z-index: 10000;

	display: flex;
	align-items: center;
	justify-content: center;

	&__body {
		background-color: white;
		width: 350px;
		height: 100px;
		display: flex;
		flex-direction: row;

	}

	&__left-color {
		width: 8px;
		background: $font-color-invono;
	}

	&__spinner {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__text {
		margin-top: 8px;
	}


}
