/**
 * Variables
 *
 * Description: Contains Sass variables
 * -----------------------------------------------------------------------------
 */

/**
 * Table of Contents:
 *
 * 1.0 - Invono
 * 2.0 - Background Colors (override)
 * 3.0 - Navigation (override)
 * 4.0 - Toggle Switch Plugin with text (extension)
 * 5.0 - Mail list (override)
 * 6.0 - Status Bar
 * 7.0 - Uploaded File
 * -----------------------------------------------------------------------------
 */


/**
 * 1.0 - Invono
 * -----------------------------------------------------------------------------
 */

$white: #fff;
$blueLight: #e3ebf1;
$greyDark: #666;
$greyLight: #dadada;
$greyLighter: #F1F1F1;
$orange: #d89519;
$green: #01ac40;
$red: #c80002;

$body-color: #6c6c6c;
$invono-accent-color: #339999;
$invono-blue: #0385ce;
$invono-grey: #a5a5a5;

$panel-bg-color: #e3ebf1;
$panel-border-color: rgba(165, 165, 165, 0.3);

$btn-tag-standard-color: #00b1c7;
$btn-tag-standard-bg: transparent;
$btn-tag-selected-color: $white;
$btn-tag-selected-bg: #00b1c7;

$margin-standard: 20px;

$placeholder-color: #d0d0d0;


/**
 * 2.0 - Background Colors (override)
 * -----------------------------------------------------------------------------
 */

$success-bg                 : #00B651;
$warning-bg                 : #D7A741;
$danger-bg                  : #D51D00;



/**
 * 3.0 - Navigation (override)
 * -----------------------------------------------------------------------------
 */

$mainnav-profile-bg: '';
$mainnav-lg-width: 240px;



/**
 * 4.0 - Toggle Switch Plugin with text (extension)
 * -----------------------------------------------------------------------------
 */

$toggle-switch--text-container-width: 65px;
$toggle-switch--text-container-height: 23px;



/**
 * 5.0 - Mail list (override)
 * -----------------------------------------------------------------------------
 */

$email-list-odd: $blueLight;
$email-list-hover: darken($blueLight, 3%);
$email-highlight: $greyLight;



/**
 * 6.0 - Status Bar
 * -----------------------------------------------------------------------------
 */
 $status-bar-col-width: 120px;
 $status-bar-col-margin:    15px;


 /**
  * 7.0 - Uploaded File
  * -----------------------------------------------------------------------------
  */
$uploaded-file-bg-color: $body-bg;
$uploaded-file-text-color: $body-color;
$uploaded-file-border-color: $greyLight;
