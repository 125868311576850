/* NAVBAR
/*====================================*/
#navbar{
    width: 100%;
    background-color: $body-bg;
}

@if lightness($mainnav-bg) < 70%{
    #navbar-container{
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    }
} @else if lightness($mainnav-bg) >= 70% and lightness($navbar-bg) >= 70%  {
    #navbar-container{
        box-shadow: 0 0 2px 0 darken($body-bg, 7.5%);
    }
}
.navbar-header{
    left: 0;
    position: relative;
    float: none;
    background-color: $brand-bg-overlay
}

.navbar-content{
    position: relative;
    left: 0;
    background-color: $navbar-bg
}

.navbar-aside-icon > *:before{
    background-color: $navbar-color !important;
    box-shadow: 0 .45em 0 $navbar-color, 0 .9em 0 $navbar-color !important
}

/* BRAND LOGO & TEXT
/*====================================*/
.navbar-brand{
    background-color: $brand-bg;
    color: $mainnav-hover-color;
    padding: 0;
    height: $navbar-height;
    position: relative;
    z-index: 2;
    overflow: hidden;
    width: $mainnav-lg-width;
    &:hover, &:focus{
        color: $mainnav-hover-color;
    }
}
.brand-title{
    display: block;
    line-height: $navbar-height;
}
.brand-icon{
    display: block;
    line-height: $navbar-height;
    width: $navbar-height;
    height: $navbar-height;
    float: left;
    margin: 0 5px
}
.brand-text{
    display: block;
    font-size: $brand-font-size;
    font-weight: $font-semibold
}




/* NAVBAR ICON & BUTTON */
/*======================================*/
.navbar-icon-color{
    color: darken($navbar-color, 3%);
    @include transition(color .4s)
}

.navbar-top-links{
    > li{
        float: left;
        > a {
            display: table-cell;
            padding: 0 12px;
            vertical-align: middle;
            height: $navbar-height;
            color: $navbar-color;
            @include transition(all .4s);
            &:not(.mainnav-toggle) > i{
                color: $navbar-color;
                @include transition(color .4s);
            }
            &:focus{
                background-color: transparent;
                &:not(.mainnav-toggle) > i{
                    @extend .navbar-icon-color
                }
            }
            &:hover{
                background-color: darken($navbar-bg, 5%);
                color: darken($navbar-color, 3%);
                @include transition(all .4s);
                &:not(.mainnav-toggle) > i{
                    @extend .navbar-icon-color
                }
            }
            > i{
                font-size: 1.2em;
                line-height: 0.75em;
            }
        }
    }
    &:first-child > li{
        border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
    &:last-child > li {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
    > .open{
        > a, > a:focus{
            background-color: darken($navbar-bg, 5%);
            color: $navbar-color;
        }
    }
    .dropdown-menu{
        .panel-body{
            padding: 0;
        }
        .nano:not(.scrollable){
            max-height: 180px;
            .nano-content{
                position: static;
            }
        }
    }
    .tgl-menu-btn{
        position: absolute;
        top: -100%;
        right: 0;
        > a, > a:hover, > a:focus{
            color: $brand-color
        }
        > a:hover, a:focus{
            background-color: transparent
        }
    }
    .head-list{
        list-style: none;
        padding: 5px 0;
        margin: 0;
        li a{
            display: block;
            padding: 10px 15px;
			padding: 5px 10px;
            background: transparent;
            &:hover{
                background: rgba(0,0,0,.05);
                color: $body-color
            }
        }
    }
}

.badge-header{
    position: absolute;
    font-size: .85em;
    font-weight: normal;
    top: 50%;
    margin-top: -1.5em;
    min-width: 1.85em;
    padding: 3px 5px;
    right: 3px;
}
.badge-header:empty{
    display: inline;
    border-radius: 50%;
    min-width: 0;
    padding: 5px;
    right: .55em;
    top: 2.2em;
    animation-iteration-count: 5;
}

.navbar-top-links .dropdown-menu .nano{
    max-height: 265px
}



/* DROPDOWN USER */
/*======================================*/
.img-user{
    @if $navbar-height >= 39{
        width: 32px;
        height: 32px;
    }@else{
        width: $navbar-height - 7;
        height: $navbar-height - 7;
    }
    box-shadow: 0 0 0 2px rgba(0,0,0,.1);

}
#dropdown-user .ic-user{
    font-size: 1.5em;
    line-height: $navbar-height
}
.username{
    float: right;
    white-space: nowrap;
    line-height: $navbar-height;
    margin: 0 10px
}




/* NAVBAR RESPONSIVE */
/*======================================*/
@media (min-width: 768px){
    #dropdown-user > .dropdown-toggle img{
        @include transform(translateY( ($navbar-height/2) - ( (min($navbar-height - 7,32)/2)) )) ;
    }
    #navbar{
        background-color: transparent
    }

    .navbar-header{
        float: left;
        background-color: transparent;
        &:before{
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: $navbar-height;
            background-color: $brand-bg-overlay
        }
    }
    #container.mainnav-out{
        .navbar-header:before{
            width: 0;
        }
    }
    #container.mainnav-in{
        .navbar-header:before{
            width: 100%;
        }
    }
    #navbar-container{
        background-color: $navbar-bg
    }
    .navbar-content{
        margin-left: 0;
        background-color: transparent
    }
    #navbar .brand-title{
        padding: 0 1.5em 0 $navbar-height;
    }

    #container.mainnav-in .navbar-brand,
    #container.mainnav-lg .navbar-brand,
    #container.mainnav-in .brand-title,
    #container.mainnav-lg .brand-title{
        width: $mainnav-lg-width;
    }

    #container.mainnav-in .brand-icon,
    #container.mainnav-sm .brand-icon,
    #container.mainnav-lg .brand-icon{
        background-color: transparent
    }

    #container.mainnav-in .brand-title,
    #container.mainnav-sm .brand-title,
    #container.mainnav-lg .brand-title{
        color: $brand-color;
    }



    #container.effect .brand-title,
    #container.effect.mainnav-in .brand-title,
    #container.effect.mainnav-sm .brand-title,
    #container.effect.mainnav-lg .brand-title{
        @include transition(color .35s)
    }
    .navbar-middle{
        position: static
    }
    .navbar-top-links{
        .tgl-menu-btn{
            position: static;
            > a, > a:hover{
                color: $navbar-color
            }
            > a:focus{
                background-color: transparent;
                color: $navbar-color;
            }
        }
    }
}

/* NAVBAR : FIXED */
/*======================================*/
#container.navbar-fixed{
    > #navbar{
        position: fixed;
        .shadow{
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1)
        }
    }
    .navbar-top-links{
        .dropdown-menu{
            .nano{
                > .nano-content{
                    position: absolute;
                }
            }
            .nano{
                max-height: 110px
            }
        }
    }
}

@media (max-width: 760px){
    #container.navbar-fixed .navbar-top-links{
        .dropdown-menu .nano{
            max-height: 110px
        }
    }
}
