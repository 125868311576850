.share-types {
	&__list-header {
		display: flex;
		flex-direction: row;
		padding-left: 4px;
		margin-bottom: 5px;
		color: $invono-grey;

	}

	&__share-class {
		padding: 0 4px;
		width: 140px;
	}

	&__share-type {
		width: 140px;
		padding: 0 4px;
	}

	&__num-of-shares {
		width: 110px;
		padding: 0 4px;
	}

	&__votes-per-share {
		width: 90px;
		padding: 0 4px;
	}
	&__latest-price {
		width: 90px;
		padding: 0 4px;
	}
	&__transaction-limitations {
		flex: 1;
		padding: 0 4px 4px 4px;
	}

	&__delete {
		width: 50px;
		padding: 0 4px;
	}
}
