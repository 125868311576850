.i-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 16px;
	padding-bottom: 0;
	background-color: $bg-color-body;

	&--pad-right-half {
		padding-right: 8px;
	}

	&--no-pad-right {
		padding-right: 0;
	}

	&__header {
		display: flex;
		height: 74px;
		align-items: center;
		padding: 0 0 16px;

		/* Company representatives specific height */
		&--cr-specific {
			height: 52px;
		}
	}

	&__page-header {
		display: flex;
		flex: 1 1 100%;

		> span {
			margin-right: 7px;
		}

		&--no-wrap {
			white-space: nowrap;
		}
	}

	&__body {
		display: flex;
		flex-direction: row;
		flex: 1;

		&--column {
			flex-direction: column;
		}
	}

	&__container {
		flex: 1;
		display: flex;
		flex-direction: column;

		&--left {
			padding-right: 10px;
			padding-bottom: 20px;
		}
		&--right {
			padding: 0 10px;
			padding-bottom: 20px;
		}

		&--menu {
			flex: 1;
			padding-bottom: 45px;
		}

		&--no-tabs {
			margin-top: 40px;
		}

		&--no-tabs-30 {
			margin-top: 30px;
		}

		&--center {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--justify-center {
			justify-content: center;
		}
	}

	&__pre-scrollbar {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		min-height: 42px;
		flex: 0 0 auto;
	}

	&__tabs {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		min-height: 40px;
		padding: 0 15px 8px 15px;

		&--fix-padding {
			padding: 0 10px 8px 10px;
		}

		.tabs-text {
			display: flex;
			flex: 1 1 100%;

			h4 {
				margin: 0;
			}
		}

		.tabs-button-container {
			display: flex;
		}

		&--left {
			justify-content: flex-start;

			&--new-meeting {
				width: 45%;
			}
		}

		&--padding-right-10 {
			padding-right: 10px;
		}

		&--padding-right-0 {
			padding-right: 0;
		}

		&--padding-btm-0 {
			padding-bottom: 0;
		}

		&--padding-left-0 {
			padding-left: 0;
		}
	}

	&__status-bar {
		display: flex;
		justify-content: flex-end;
		width: 900px;
		height: 100%;
	}

	&__panel {
		display: flex;
		flex-direction: row;
		min-height: 185px;
		padding: 0;
		margin: 15px 0;

		> .i-panel {
			flex: 1;
		}
	}
}
