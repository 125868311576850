.base-layout {
	display: flex;
	flex-direction: row;
  flex: 1;
  min-height: 1px;
	overflow: hidden;

	&__body {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding-bottom: 0;

		&__nav-bar {
			height: 40px;
		}
		&__content {
			flex: 1;
			max-width: 1500px;
			overflow: auto;
		}
	}

	&--dmz {

	}
}

.float-right {
	float: right;
}

.visibility-hidden {
	visibility: hidden;
}
